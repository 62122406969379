import styled from 'styled-components'
import { lg, md, xl } from 'config/variables'

export const ShoppingexpsecStyles = styled.div`
  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 60px;
    ${xl(`
        margin-bottom: 38px;
      `)}
       ${lg(`
       flex-direction: column;
       margin-bottom: 38px;
      `)}
      ${md(`
       margin-bottom: 30px;
      `)}
    .col-content {
      max-width: 540px;
      width: 100%;
      ${lg(`
       max-width: 100%;
       padding-bottom:60px;
      `)}
      ${md(`
       max-width: 100%;
       padding-bottom:40px;
      `)}
      p {
        margin: 0;
      }
    }
    .col-img {
      max-width: 536px;
      width: 100%;
    }
    .img-blk {
      max-width: 717px;
      width: 100%;
      ${xl(`
        max-width: 100%;
      `)}
      ${lg(`
        max-width: 100%;
      `)}
      &.PcCartThumb {
      ${xl(`
        display:none;
      `)}
      ${lg(`
        display:none;
      `)}
      }
      &:first-child {
        max-width: 400px;
      }
    }
  }
`
