import React from 'react'

import { CasebuildingappsecStyles } from './Casebuildingappsec.styles'

function Casebuildingappsec(props) {
  const { caseBuildingAppProps } = props.caseBuildingAppdata
  return (
    <CasebuildingappsecStyles>
      <div className="container">
        <h2>{caseBuildingAppProps.heading}</h2>
        <div className="row">
          <div className="content-col">
            {caseBuildingAppProps.subHead ? (
              <h3>{caseBuildingAppProps.subHead}</h3>
            ) : (
              ''
            )}
            <p>{caseBuildingAppProps.para}</p>
          </div>
          <div className="content-col">
            {caseBuildingAppProps.serviceLists ? (
              <div className="servicesInnerRow">
                {caseBuildingAppProps.serviceLists.map(
                  (resultServicelist, i) => {
                    return (
                      <div
                        key={i}
                        className={`serviceItem ${resultServicelist.className}`}
                      >
                        <div className="icon">
                          <img src={resultServicelist.icon} alt="" />
                        </div>
                        <div className="content-blk">
                          <h4>{resultServicelist.title}</h4>
                        </div>
                      </div>
                    )
                  }
                )}
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </CasebuildingappsecStyles>
  )
}

export default Casebuildingappsec
