import React from 'react'
import { ShoppingexpsecStyles } from './Shoppingexpsec.styles'
import Image from 'components/ImgOptimized'
import Casequotesec from 'components/Casequotesec/Casequotesec'

function Shoppingexpsec(props) {
  const {
    shoppingexpsecdata: { shoppingExpSecProps },
    showTopRightImg,
    showBottomLeftImg,
    showBottomRightImg,
  } = props
  const caseQuoteProp = shoppingExpSecProps.caseQuoteProps
  const {
    data: {
      samsClub: {
        childImageSharp: { fluid: samsClub },
      },
      pcCart: {
        childImageSharp: { fluid: pcCart },
      },
      shoppingDb: {
        childImageSharp: { fluid: shoppingDb },
      },
    },
  } = props

  return (
    <ShoppingexpsecStyles className="shoppingExpSec">
      <div className="container">
        <h2>{shoppingExpSecProps.heading}</h2>
        <div className="row">
          <div className="col-content">
            <p>{shoppingExpSecProps.topPara}</p>
          </div>
          {showTopRightImg && (
            <div className="col-img">
              <Image
                fluid={samsClub}
                lazyload={{
                  enabled: true,
                  once: true,
                  offset: 1000,
                  heigth: 631,
                }}
              />
            </div>
          )}
        </div>

        {showBottomLeftImg || showBottomRightImg ? (
          <div className="row">
            {showBottomLeftImg && (
              <div className="img-blk PcCartThumb">
                <Image
                  fluid={pcCart}
                  lazyload={{
                    enabled: true,
                    once: true,
                    offset: 1000,
                    heigth: 397,
                  }}
                />
              </div>
            )}
            {showBottomRightImg && (
              <div className="img-blk">
                <Image
                  fluid={shoppingDb}
                  lazyload={{
                    enabled: true,
                    once: true,
                    offset: 1000,
                    heigth: 429,
                  }}
                />
              </div>
            )}
          </div>
        ) : (
          ''
        )}
        <p>{shoppingExpSecProps.bottomPara}</p>
      </div>
      {caseQuoteProp && <Casequotesec {...caseQuoteProp} />}
    </ShoppingexpsecStyles>
  )
}

export default Shoppingexpsec
