import React from 'react'
import Layout from 'components/Layout/Layout'
import SEO from 'components/Seo'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { transTMSProps } from 'components/Props/trans-tms'
import { useLazyLoad } from 'components/hooks/useLazyload'
import { useAnimationClass } from 'components/hooks/useAnimationClass'
import { xl, lg, md, sm } from 'config/variables'
import Locaranimationbanner from 'components/Locaranimationbanner/Locaranimationbanner'
import Caseintroduction from 'components/Caseintroduction/Caseintroduction'
import Engagementsec from 'components/Engagementsec/Engagementsec'
import Tmscaseresultsec from 'components/Tmscaseresultsec/Tmscaseresultsec'
import Casecontentfullwimg from 'components/Casecontentfullwimg/Casecontentfullwimg'
import Casebuildingappsec from 'components/Casebuildingappsec/Casebuildingappsec'
import Shoppingexpsec from 'components/Shoppingexpsec/Shoppingexpsec'
import Caseengagementsec from 'components/Caseengagementsec/Caseengagementsec'
import Additionalappfeaturesec from 'components/Additionalappfeaturesec/Additionalappfeaturesec'
import Reliabilityinstancessec from 'components/Reliabilityinstancessec/Reliabilityinstancessec'
import Casecontactcardinfo from 'components/Casecontactcardinfo/Casecontactcardinfo'
import Relatedcaselist from 'components/Relatedcaselist/Relatedcaselist'

export const TransTMSPage = styled.div`
  .caseAnimationBanner {
    padding-bottom: 120px; 
    ${xl(`
       padding-bottom: 90px;
    `)}
    ${lg(`
       padding-bottom: 60px; 
    `)}
    ${md(`
       padding-bottom: 40px; 
    `)} 
    .img-block { 
      .bannerImage {
        width: 622px;
        position: relative;
        height:460px;
        ${xl(`
          width: 460px;
          height: 350px;
        `)}
        ${lg(`
            width: 351px;
            height:260px;
        `)}
        ${sm(`
           width: 280px;
           height: 215px;
        `)}
        img {
          position:absolute;
          &.laptopScreen {
            left:-15px;
            bottom:0px; 
            ${xl(`
                max-width: 430px; 
            `)}
            ${lg(`
                max-width: 340px; 
            `)}
            ${sm(`
              max-width: 280px;  
            `)}
          }   
          &.rotateImage { 
            top: 117px;
            left: -10px;
            ${xl(`
              top: 110px;
              left: -14px;
              max-width: 80px;
          `)}
           ${lg(`
              top: 74px;
              left: -9px;
              max-width: 60px;
            `)}
           ${sm(`
              top: 60px;
              left: -9px;
              max-width: 50px;
            `)}
          }   
          &.actionBtn {
            right: 0;
            top: 150px;
            transform: translatex(0px) scale(0.3);
	          animation: float 8s ease-in-out infinite;
            ${xl(`
              max-width: 200px; 
              top: 140px;
          `)}
            ${lg(`
              max-width: 150px;
              top: 90px;
          `)}
            ${sm(`
              max-width: 120px;
              top: 80px;
          `)}
          }   
          &.containerImg {
            left: 70px;
            top: -3px;
            transform: translatey(0px);
	          animation: float 8s ease-in-out infinite; 
            z-index: -1;
            ${xl(`
                max-width: 200px;
                left: 30px; 
            `)}
            ${lg(`
              max-width: 130px;
              left: 26px;
            `)}
            ${sm(`
              max-width: 100px;
              left: 26px;
            `)}
          }   
        }        
      }
    }
  }
  .engagementSec  {
    .mobileAppsContentLeft { 
      padding-right: 40px;
      padding-left: 80px;
      ${xl(`
           padding-left: 124px;
           padding-right: 25px; 
      `)} 
      ${lg(`
           padding-right: 15px;
           padding-left: 124px; 
      `)} 
      ${md(`
        flex:1 1 auto;
        padding:0px;
      `)}     
    }
  }
  .Casecontentfullwimg {
    figure {
      margin: 0;
    }
  }
  .tmsDataScreensSec {
    .Casecontentfullwimg {
      padding:100px 0;
      background:#d9e2e8;
      ${xl(`
        padding:80px 0;
      `)}
      ${lg(`
        padding:60px 0;
      `)}
      ${md(`
        padding:40px 0;
      `)} 
    }
  }
  .shoppingExpSec {
    margin-bottom: 120px; 
    ${xl(`
       margin-bottom: 90px;
    `)}
    ${lg(`
       margin-bottom: 60px; 
    `)}
    ${md(`
       margin-bottom: 40px; 
    `)}
    h2 {
      margin-bottom: 30px;
      padding-bottom: 30px;
      position: relative;
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0px;
        width: 251px;
        height: 3px;
        background: #3d3d3d;
      } 
      .row  {
        margin-bottom: 20px;
        .col-img {
          max-width:651px;
        }
      }
    }
  }
  .hostingSolution {
    .caseEngagementSec  {
      margin:0 0 15px;
    }
  }
  .hostingSolutionSec {
    .Casecontentfullwimg {
      figure {
        margin: 0 0 30px;
      }
      .content-container {
        margin-right:0px;
      }
    }
  }
  .additionalAppFeaturesec {
    padding-bottom: 100px;
    position: relative;
    ${xl(`
       padding-bottom: 80px;
    `)}
    ${lg(`
       padding-bottom: 60px;
    `)}
    ${md(`
       padding-bottom: 40px;
    `)}
    &::after {
      content: '';
      background: linear-gradient(to bottom, rgba(252, 253, 255, 0), #f0f4f8);
      position: absolute;
      width: 100%;
      height: 633px;
      bottom: 0;
      left: 0;
      z-index: -1;
    } 
  }
`
const TransTMS = props => {
  const { data } = props
  const { location } = props

  const dashboardScreen = props.data.dashboardScreen.childImageSharp.fluid
  const tmsDataScreen = props.data.tmsDataScreen.childImageSharp.fluid
  const hostingSolutionDaigram =
    props.data.hostingSolutionDaigram.childImageSharp.fluid

  let featureImg = [
    props.data.walletImg.childImageSharp.fluid,
    props.data.checkoutsScreeen.childImageSharp.fluid,
    props.data.moneySaveScreen.childImageSharp.fluid,
  ]
  let itembottom = [props.data.mixpanel1.childImageSharp.fluid]
  const tmsShipmentsScreen = props.data.tmsShipmentsScreen.childImageSharp.fluid

  const { showContent } = useLazyLoad('.lazy-load-div')
  useAnimationClass({ showContent })

  return (
    <Layout mainClass="case-studie-pages" location={location}>
      <SEO
        title="Trans TMS"
        description=""
        keywords={[`simform`]}
        links={[
          {
            href: 'https://www.simform.com/case-studies/trans-tms/',
            rel: 'canonical',
          },
        ]}
        meta={[
          {
            property: 'og:url',
            content: 'https://www.simform.com/case-studies/trans-tms/',
          },
          {
            property: 'og:image',
            content: data && data.bannerImage.childImageSharp.fluid.src,
          },
        ]}
      />
      <TransTMSPage>
        <Locaranimationbanner bannerdata={transTMSProps} />
        <Caseintroduction
          caseintrodata={transTMSProps}
          {...props}
          showFullimages={false}
        />
        <div className="lazy-load-div">
          <Engagementsec
            engagementsecdata={transTMSProps}
            {...props}
            showFullimages={false}
          />
          <Tmscaseresultsec tmsCaseResultData={transTMSProps} />
        </div>
        {showContent && (
          <>
            <Casecontentfullwimg
              casecontentfullwdata={transTMSProps.casecontentfullwProps}
              fluid={dashboardScreen}
            />
            <div className="tmsDataScreensSec">
              <Casecontentfullwimg
                casecontentfullwdata={transTMSProps.casecontentfullwProps}
                fluid={tmsDataScreen}
              />
            </div>
            <Casebuildingappsec caseBuildingAppdata={transTMSProps} />
            <Shoppingexpsec
              shoppingexpsecdata={transTMSProps}
              {...props}
              showTopRightImg={true}
              showBottomLeftImg={false}
              showBottomRightImg={false}
            />
            <div className="hostingSolution">
              <Caseengagementsec
                Caseengagementsecdata={transTMSProps.CaseengagementsecProps}
              />
            </div>
            <div className="hostingSolutionSec">
              <Casecontentfullwimg
                casecontentfullwdata={transTMSProps.hostingSolutionProps}
                fluid={hostingSolutionDaigram}
              />
            </div>
            <Additionalappfeaturesec
              additionalFeatureData={transTMSProps}
              imgData={featureImg}
            />
            <Caseengagementsec
              Caseengagementsecdata={transTMSProps.automatingSalesProps}
            />
            <div className="tmsDataScreensSec">
              <Casecontentfullwimg
                casecontentfullwdata={transTMSProps.casecontentfullwProps}
                fluid={tmsShipmentsScreen}
              />
            </div>
            <Reliabilityinstancessec
              ReliabilityinstancesData={
                transTMSProps.ReliabilityinstancesTopProps
              }
              {...props}
              fluid={itembottom}
            />
            <Relatedcaselist relatedcaselistdata={transTMSProps} {...props} />
            <Casecontactcardinfo Casecontactcardinfodata={transTMSProps} />
          </>
        )}
      </TransTMSPage>
    </Layout>
  )
}

export default TransTMS

export const pageQuery = graphql`
  query {
    bannerImage: file(
      relativePath: { regex: "/transtms-banner-image@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 570) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    businessFullImage: file(
      relativePath: { regex: "/way-point-intro-img@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1140) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    fihmainscreen: file(
      relativePath: { regex: "/fts-challenges-img1@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 981) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    dashboardScreen: file(
      relativePath: { regex: "/transtms-dashboard-screen@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1088) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    tmsDataScreen: file(
      relativePath: { regex: "/transtms-data-screen@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 930) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    samsClub: file(
      relativePath: { regex: "/transtms-apiedit-integration@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 651) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    pcCart: file(relativePath: { regex: "/pc-cart@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 400) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    shoppingDb: file(relativePath: { regex: "/shopping-db@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 717) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    hostingSolutionDaigram: file(
      relativePath: { regex: "/transtms-hosting-daigram@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1140) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    walletImg: file(relativePath: { regex: "/tms-automated-img@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 506) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    checkoutsScreeen: file(
      relativePath: { regex: "/tms-automated-tracking@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 506) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    moneySaveScreen: file(
      relativePath: { regex: "/tms-route-prediction-img@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 506) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    mixpanel1: file(relativePath: { regex: "/tms-power-bi-images@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 552) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    tmsShipmentsScreen: file(
      relativePath: { regex: "/transtms-shipments-screen@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1088) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    singlecase1: file(
      relativePath: { regex: "/accent-casestudies-thumb@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 220) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    singlecase2: file(
      relativePath: { regex: "/roundr-casestudies-thumb@2x.jpg/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 220) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
  }
`
