import styled from 'styled-components'
import { lg, xl, md } from 'config/variables'

export const CasebuildingappsecStyles = styled.div`
  position: relative; 
  margin-bottom: 120px;
  ${xl(`
      margin-bottom: 90px; 
  `)} 
   ${lg(`
      margin-bottom: 60px; 
  `)} 
  ${md(`
      margin-bottom: 40px; 
  `)} 

.row {
    display: flex;
    justify-content: space-between; 
    ${lg(`
        margin:0;
        flex-direction: column;
    `)}  
    .content-col {
      flex: 0 1 50%; 
       ${lg(` 
          flex: 1 1 auto;
      `)}  
      &:first-child {
        padding-right:30px;
        ${lg(`
            padding-right:0;
            padding-bottom:30px;
        `)} 
         ${md(`
            padding-bottom:10px;
        `)} 
      } 
      &:last-child {
        padding-left:30px;
        ${lg(`
            padding-left:0; 
        `)} 
      } 
      .servicesInnerRow {
        border: solid 1px #e4e4e6;
        background-color: #ffffff80;
        .serviceItem {
          display:flex;
          align-items:center;
          border-bottom: solid 1px #e4e4e6;  
          padding:28px 10px 28px 34px;
          ${xl(`
             padding:20px 10px 20px 20px;
          `)} 
          ${lg(`
              padding:15px;
          `)} 
          .icon {
            width:60px;
            height:60px;
            flex:0 0 60px;
            ${xl(`
              width:50px;
              height:50px;
              flex:0 0 50px;
          `)} 
            ${lg(`
              width:40px;
              height:40px;
              flex:0 0 40px;
          `)} 
          }
          .content-blk {
            padding-left:30px;
            ${xl(`
              padding-left:20px;
            `)} 
            ${lg(`
              padding-left:15px;
            `)} 
            h4 {
              font-size: 26px;
              font-weight: 600;
              margin:0px;
              ${xl(`
                 font-size: 24px;
                 line-height: 26px;
              `)} 
              ${lg(`
                  font-size: 20px;
                  line-height: 26px;
              `)} 
            }
          }
          &:last-child{
            border-bottom:0px;
          }
          &.sage-green {
            .content-blk {
              h4 {
                color:#85c26a;
              }
           }
          }
          &.blush {
            .content-blk {
              h4 {
                color:#f6b684;
              }
            }
          }
          &.sky-blue {
            .content-blk {
              h4 {
                color:#6cbaf7;
              }
            }
          }
          &.pale-gold {
            .content-blk {
              h4 {
                color:#ffce6b;
              }
            }
          }
          &.aqua-marine  {
            .content-blk {
              h4 {
                color:#29ded5;
              }
            }
          }
          &.perrywinkle  {
            .content-blk {
              h4 {
                color:#8d7fd8;
              }
            }
          }
        }
      }
    }
`
